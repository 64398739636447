import { render, staticRenderFns } from "./DepartmentCopy.vue?vue&type=template&id=1f7f9cb9&xmlns%3Abackground-color=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&"
import script from "./DepartmentCopy.vue?vue&type=script&lang=js&"
export * from "./DepartmentCopy.vue?vue&type=script&lang=js&"
import style0 from "./DepartmentCopy.vue?vue&type=style&index=0&id=1f7f9cb9&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/jobs/management-system-frontend/workspace/ant-design-vue-jeecg/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f7f9cb9')) {
      api.createRecord('1f7f9cb9', component.options)
    } else {
      api.reload('1f7f9cb9', component.options)
    }
    module.hot.accept("./DepartmentCopy.vue?vue&type=template&id=1f7f9cb9&xmlns%3Abackground-color=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&", function () {
      api.rerender('1f7f9cb9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/organization/DepartmentCopy.vue"
export default component.exports