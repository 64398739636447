var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("body-layout", [
    _c(
      "div",
      [
        _c("vue2-org-tree", {
          attrs: { data: _vm.treeData },
          on: { "on-node-click": _vm.handleClick },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }